import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '@core/auth';
import { SidePanelService } from '../side-panel/services/side-panel.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  isAuthenticated = false;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    public authService: AuthService,
    public sidePanelService: SidePanelService
  ) { }

  ngOnInit() {

    this.keycloakService.isLoggedIn().then(data => {
      if (data) {
        this.isAuthenticated = true;
      } else {
        const sub = this.authService.isAuthenticated().subscribe(data => {
          if (data) {
            this.isAuthenticated = true;
          } else {
            this.isAuthenticated = false;
          }
          sub.unsubscribe();
        })
      }
    })
  }

  signIn() {
    this.router.navigate(['/welcome']);
  }

  toggleSidePanel() {
    this.sidePanelService.toggleSidePanelState();
  }

}
