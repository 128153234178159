<div class="side-pannel" [ngClass]="{ 'side-pannel-collapsed': !(showSidePanel | async) }">

    <div class="side-pannel-overlay"
        [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's' }"
        [ngClass]="{ 'side-pannel-overlay-collapsed': !(showSidePanel | async) }" (click)="onSidePanelClose()">
    </div>


    <div class="side-pannel-menu-container header-hight d-flex" [ngStyle]="getShowPanelStyle((showSidePanel | async))">
        <div class="hook" (click)="onSidePanelClose()">
            <span>
                <app-icon class="icon mb-1" svgName="double-right" svgSize="16" fill="#A9ADBF" bg='black'>
                </app-icon>
            </span>
        </div>
        <div class="info-area">
            <div class="ms-3">
                <label class="title">My Profile</label>
            </div>
            <div class="d-flex user-container">
                <div class="image-container">
                    <div class="user-image"><span class="initials">{{userService.initials |  uppercase}}</span></div>
                </div>
                <div class="user-info">
                    <label class="lbl-user-name">
                        {{userService.user?.given_name}} {{userService.user?.family_name}}
                    </label>
                    <label class="lbl-user-email">
                        {{userService.user?.email}}
                    </label>
                </div>
                <div class="logout">
                    <span class="lbl-logout" (click)="signOut()">Sign out</span>
                </div>
            </div>

            <div class="side-pannel-content-container">
                <ng-container *ngTemplateOutlet="sidePanelTemplateRef"></ng-container>
            </div>
        </div>

    </div>
</div>