import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  constructor(private http: HttpClient) {}

  public getRequests() {
    return this.http.get(`/plugins/request`);
  }
}
