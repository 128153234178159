<ng-template #rt let-r="result" let-t="term">
    <div>{{ r[resultFormat] }}</div>
</ng-template>
<div class="my-2" *ngIf="multiple">
    <span class="tag mr-2" *ngFor="let selected of _selected">{{selected.name}}
        <span (click)="removeItem(selected)" class="text-dark" aria-hidden="true">&times;</span>
    </span>
</div>
<label [attr.for]="id">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger" *ngIf="requiredMsg"> *</span>
  </label>
<div class="d-flex flex-fill align-items-center w-100">
    <div class="position-relative d-flex align-items-center w-100">
        <input id="typeahead-template" type="text" class="search-box w-100" [placement]="placement"
            [placeholder]="placeholder" [(ngModel)]="_model" [ngbTypeahead]="search" [resultTemplate]="rt"
            [inputFormatter]="formatter" [editable]="editable" (keydown)="onKeydown($event)"
            (ngModelChange)="onModelChange($event)" popupClass="typeahead-window" />
        <app-icon class="icon mb-1 icon-placement " svgName="search-1" svgSize="18" fill="#A9ADBF" bg='black'>
        </app-icon>
    </div>
    <div class="ml-2" *ngIf="addButton">
        <button class="btn btn-primary text-white" (click)="selectItem()" [disabled]="!_model">
            <app-icon class="icon" svgName="add-row" svgSize="24" status="white" [svgStyle]="{ margin: '0 0 2px 0' }">
            </app-icon>
            Add
        </button>
    </div>
</div>