import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../icon/icon.module';
import { HeaderComponent } from './header.component';
import { HeaderService } from './header.service';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, NgbCollapseModule, IconModule, NgbTooltipModule],
  providers: [HeaderService],
  exports: [HeaderComponent],
})
export class HeaderModule { }
