import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { assign, filter, find, remove } from 'lodash';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss']
})
export class TypeaheadComponent implements OnChanges {

  @Input() id = 1;
  @Input() data: any;
  @Input() multiple = false;
  @Input() inputFormat: any;
  @Input() resultFormat: any;

  /** Show a separate button to select Item */
  @Input() addButton = false;
  @Input() placement: PlacementArray;
  @Input() placeholder = 'Search';
  @Input() editable = false;
  @Input() characterLength = 1;
  @Input() requiredMsg;
  @Output() itemSelected = new EventEmitter();
  @Output() searchKeyword = new EventEmitter();

  @Input() _model: any;
  @Input() _selected: any;
  _searchData$ = new Subject();

  constructor() { }

  formatter = (r: any) => r[this.inputFormat];

  search = (text$: any) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((term: string) => {
      if (!term || term.length < this.characterLength) {
        return of([]);
      }
      this.searchKeyword.emit(term);
      return this._searchData$;
    }
    ));

  ngOnChanges(changes: any) {
    if (changes.data) {
      this.updateSearchData();
    }
  }

  updateSearchData() {
    if (this.multiple && this._selected) {
      this.data = this.data.filter((i: any) => !this._selected || find(this._selected, { id: i.id }) === undefined)
    }
    this._searchData$.next(this.data);
  }

  onModelChange($event: any) {
    if (this.addButton) {
      return;
    }
    this.selectItem();
  }

  selectItem() {
    if (this._model) {
      if (this.multiple) {
        if (this._selected) {
          this._selected.push(this._model);
        } else {
          this._selected = [this._model];
        }
      } else {
        this._selected = { ...this._model };
      }
      this.updateSearchData();
      this.itemSelected.emit({ model: this._selected });

      if (this.addButton || this.multiple) {
        this._model = null;
      }
    }
  }

  removeItem(item: any) {
    remove(this._selected, item);
    this.itemSelected.emit({ model: this._selected });
  }

  onKeydown(event: any) {
    if (event.key === 'Backspace') {
      if (this._model) {
        this._model = null;
      }
    } else {
      if (this._model) {
        event.preventDefault();
      }
    }
  }
}
