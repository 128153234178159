<ng-container *ngIf="!_viewAllMode && _pages > 1">
    <div class="paginator-container flex-container my-4 ml-3" [ngClass]="getClassPosition()">
        <div class="flex-child start">
            <span class="total-title">Total: </span>
            <span class="total-text ms-1">{{ this.count || 0}}</span>
        </div>
        <div class="flex-child center">
            <span class="total-text">{{pageInfo}}</span>
        </div>
        <div class="flex-child end paginator-wrapper">
            <button class="btn-reset" [disabled]="_currentPage === 1" (click)="goToPage(1)">
                <svg width="14" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.55469 5.5L5.76562 8.73438C6.07031 9.01562 6.07031 9.50781 5.76562 9.78906C5.625 9.92969 5.4375 10 5.25 10C5.03906 10 4.85156 9.92969 4.71094 9.78906L0.960938 6.03906C0.65625 5.75781 0.65625 5.26562 0.960938 4.98438L4.71094 1.23438C4.99219 0.929688 5.48438 0.929688 5.76562 1.23438C6.07031 1.51562 6.07031 2.00781 5.76562 2.28906L2.55469 5.5ZM7.05469 5.5L10.2656 8.73438C10.5703 9.01562 10.5703 9.50781 10.2656 9.78906C10.125 9.92969 9.9375 10 9.75 10C9.53906 10 9.35156 9.92969 9.21094 9.78906L5.46094 6.03906C5.15625 5.75781 5.15625 5.26562 5.46094 4.98438L9.21094 1.23438C9.49219 0.929688 9.98438 0.929688 10.2656 1.23438C10.5703 1.51562 10.5703 2.00781 10.2656 2.28906L7.05469 5.5Z"
                        fill="#3B4957" />
                </svg>
            </button>
            <button class="ms-3 btn-reset" [disabled]="_currentPage === 1" (click)="goToPage(_currentPage - 1)">
                <svg width="12" height="12" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.75 10C4.53906 10 4.35156 9.92969 4.21094 9.78906L0.460938 6.03906C0.15625 5.75781 0.15625 5.26562 0.460938 4.98438L4.21094 1.23438C4.49219 0.929688 4.98438 0.929688 5.26562 1.23438C5.57031 1.51562 5.57031 2.00781 5.26562 2.28906L2.05469 5.5L5.26562 8.73438C5.57031 9.01562 5.57031 9.50781 5.26562 9.78906C5.125 9.92969 4.9375 10 4.75 10Z"
                        fill="#3B4957" />
                </svg>

            </button>


            <ng-container *ngFor="let pageLink of _pageLinks; let first=first; let last=last">
                <button class="page-number" [class.ms-2]="first" [class.me-2]="last"
                    [class.active]="_currentPage === pageLink.page" (click)="goToPage(pageLink.page)">
                    {{ pageLink.page }}
                </button>
            </ng-container>

            <button class="btn-reset" [disabled]="_currentPage === _pages" (click)="goToPage(_currentPage + 1)">
                <svg width="12" height="12" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1.55797 0.778929C1.74897 0.778929 1.94097 0.851929 2.08697 0.997929L5.57397 4.46793C5.71497 4.60893 5.79397 4.79993 5.79397 4.99993C5.79397 5.19893 5.71497 5.38993 5.57397 5.53093L2.08697 9.00293C1.79397 9.29493 1.31997 9.29493 1.02697 9.00093C0.73497 8.70693 0.73597 8.23193 1.02897 7.93993L3.98197 4.99993L1.02897 2.05993C0.73597 1.76793 0.73497 1.29393 1.02697 0.999929C1.17297 0.85193 1.36597 0.778929 1.55797 0.778929Z"
                        fill="#9298AB" />
                </svg>
            </button>
            <button class="ms-3 btn-reset" [disabled]="_currentPage === _pages" (click)="goToPage(endPage)">
                <svg width="14" height="14" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.01562 4.48438C6.32031 4.76562 6.32031 5.25781 6.01562 5.53906L2.26562 9.28906C2.125 9.42969 1.9375 9.5 1.75 9.5C1.53906 9.5 1.35156 9.42969 1.21094 9.28906C0.90625 9.00781 0.90625 8.51562 1.21094 8.23438L4.42188 5L1.21094 1.78906C0.90625 1.50781 0.90625 1.01562 1.21094 0.734375C1.49219 0.429688 1.98438 0.429688 2.26562 0.734375L6.01562 4.48438ZM10.5156 4.48438C10.8203 4.76562 10.8203 5.25781 10.5156 5.53906L6.76562 9.28906C6.625 9.42969 6.4375 9.5 6.25 9.5C6.03906 9.5 5.85156 9.42969 5.71094 9.28906C5.40625 9.00781 5.40625 8.51562 5.71094 8.23438L8.92188 5L5.71094 1.78906C5.40625 1.50781 5.40625 1.01562 5.71094 0.734375C5.99219 0.429688 6.48438 0.429688 6.76562 0.734375L10.5156 4.48438Z"
                        fill="#C9CFD4" />
                </svg>
            </button>
        </div>
    </div>
</ng-container>