import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {

  private sidePanelVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  ngOnInit() {
  }

  getSidePanelVisibility() {
    return this.sidePanelVisibility$.asObservable();
  }

  setSidePanelVisibility(showHide: boolean) {
    this.sidePanelVisibility$.next(showHide);
  }

  toggleSidePanelState() {
    this.sidePanelVisibility$.next(!this.sidePanelVisibility$.value);
  }

  isSidePanelOpen() {
    return this.sidePanelVisibility$.value;
  }
}
