import { IReader } from '@core/interfaces/reader.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class ApprovalDataSource extends IReader {
  renderMode = 'client';

  clientSideSortCompareFn(sortCriteria: { field: string; order: string }): (a: any, b: any) => number {
    return (a, b) => {
      const order = sortCriteria.order === 'asc' ? 1 : -1;
      const aField = a[sortCriteria.field] || '';
      const bField = b[sortCriteria.field] || '';
      if (aField.toLowerCase() > bField.toLowerCase()) {
        return 1 * order;
      } else if (bField.toLowerCase() > aField.toLowerCase()) {
        return -1 * order;
      } else {
        return 0;
      }
    };
  }
  clientSideSearchFn(data: any): (item: any) => boolean {
    let searchText = data.value?.toLowerCase() || '';
    return (item: any) => {
      let eachItemData = [];
      eachItemData = Object.values(item);
      if (data.fields && data.fields.length > 0) {
        eachItemData = data.fields.map((x: any) => item[x]);
      }
      let values = eachItemData.map((val: any) => {
        if (typeof val === 'string') return val.toLowerCase();
        return val;
      });
      return values.join('|').indexOf(searchText) !== -1;
    };
  }
  clientSideFilterFn(filteringCriteria: { field: string; value: string }[]): (item: any) => boolean {
    throw new Error('Method not implemented.');
  }
  serverSidePageData(
    filteringCriteria: { field: string; value: string }[],
    searchCriteria: string,
    sortCriteria: { field: string; order: string },
    pageCriteria: { page: number; size: number }
  ): import('rxjs').Observable<any[]> {
    throw new Error('Method not implemented.');
  }
}
