import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER, Injector } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes';
import { AppConfigurationsService } from './services';
import { AuthService, TokenService } from './auth';
import { TokenStorage } from './auth/services';
import { TruncatePipe } from '@theme/layouts/pipes/truncate.pipe';
import { Data } from './services/data.service';
import { UserService } from './services/user.service';
import { CommunicationService } from './services/communication.service';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MessageService } from './services/message.service';
import { StorageService } from './services/storage.service';
import { WelcomeService } from 'app/modules/welcome/welcome.service';
import { Router } from '@angular/router';
import { CompanyService } from './services/company.service';

const PROVIDERS = [
  AppConfigurationsService,
  AuthService,
  TokenService,
  TokenStorage,
  Data,
  UserService,
  TitleCasePipe,
  CommunicationService,
  MessageService,
  StorageService,
  CompanyService
];
const DECLARATIONS = [SafeHtmlPipe, TruncatePipe];
export function initApplication(service: AppConfigurationsService, userService: UserService) {
  return () =>
    service
      .load()
      .then(() => service.initKeycloak())
      .then(() => service.currentUser())
      .then(() => {
        // userService.getUserAttribute('Last_Login').subscribe((attr:any[]) => {
        //   if(attr.length > 0 && !JSON.parse(localStorage.getItem('user_identity'))){
        //     const payload = {
        //       attributes: [{
        //         key: "Last_Login",
        //         value: new Date
        //       }]
        //     }
        //     userService.saveUserAttribute(payload).subscribe(() => {
        //       console.log('Login time update')
        //     }, error => {
        //       console.log('Failed to update login time')
        //     })
        //   } 
        // })
      });
}
@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
  exports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule, ...DECLARATIONS],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // ImportGuard.throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initApplication,
          deps: [AppConfigurationsService, UserService],
          multi: true,
        },
        ...PROVIDERS,
      ],
    } as ModuleWithProviders<CoreModule>;
  }
}
