<div class="d-flex flex-column h-100 ms-3" [loader]="loading">
    <div class="d-flex align-items-center mt-1 mb-1 flex-grow-0">
        <div class="flex-grow-1">
            <img src="assets/icons/back.svg" style="width:10px; cursor: pointer;" (click)="back()" />
            <span class="back ms-1" (click)="back()">BACK</span>
            <span class="nav-title ms-3">{{ 'Plugin Approvals' | uppercase }}</span>
        </div>
    </div>
    <div class="d-flex mt-3">
        <span class="title">Manage Plugin Requests</span>
    </div>
    <div class="row mt-4 w-100">
        <div class="col-12 pe-4">
            <div class="d-flex justify-content-end flex-0">
                <div class="d-flex align-items-center">
                    <input type="text" placeholder="Search Plugin Requests" (keyup)="onKeyup($event)" />
                    <app-icon class="icon mb-1 icon-placement" svgName="search-1" svgSize="18" fill="#A9ADBF"
                        bg="black">
                    </app-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-4 w-100">
        <div class="col-12">
            <app-data-table [dataSource]="dataSourceService" theme="light" [pageSize]="5" paginationPosition="end">
                <app-data-table-column name="id" label="ID">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.id}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="title" label="Name">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text" [ngbTooltip]="row.title" placement="top auto">{{(row.title || '-') |
                            truncate: ['50',
                            '...']}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="description" label="Description">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text" [ngbTooltip]="row.description" placement="top auto">
                            {{(row.description || '-' ) | truncate: ['255', '...' ]}}
                        </span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="owner" label="Submitter">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.submitter || '-'}}</span>
                    </ng-container>
                </app-data-table-column>

                <app-data-table-column name="contributor" label="Contributor">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.contributor || '-'}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="domainNames" label="Domains">
                    <ng-container *smDatatableCell="let row">
                        <div class="d-flex align-items-baseline" *ngFor="let domain of row.domainsArray; let last=last">
                            <span class="ms-1">
                                <img src="assets/{{domain.icon}}" class="domain-image" width="15px" height="15px" />
                            </span>
                            <span class="td-text ms-1">{{domain.title || '-'}}</span>
                        </div>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="platformName" label="Solution">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.platformName || '-'}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="type" label="Type">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.type || '-'}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="visibility" label="Visibility">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{row.visibility || '-'}}</span>
                    </ng-container>
                </app-data-table-column>

                <app-data-table-column name="stage" label="Stage">
                    <ng-container *smDatatableCell="let row">
                        <span class="td-text ">{{(row.stage | titlecase) || '-'}}</span>
                    </ng-container>
                </app-data-table-column>
                <app-data-table-column name="action" label="Action">
                    <ng-container *smDatatableCell="let row">
                        <a [routerLink]="'/publish/'+row.id" class="review-link">Review</a>
                    </ng-container>
                </app-data-table-column>


                <span noData>No plugin requests found.</span>
            </app-data-table>
        </div>
    </div>
</div>