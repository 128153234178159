import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublishComponent } from './publish/publish.component';
import { PluginRoutingModule } from './plugin-routing.module';
import { ThemeModule } from '@theme/theme.module';
import { FormsModule } from '@angular/forms';
import { ImageSelectionComponent } from './publish/image-selection/image-selection.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalComponent } from './approval/approval.component';




@NgModule({
  declarations: [
    PublishComponent,
    ImageSelectionComponent,
    ApprovalComponent
  ],
  imports: [
    CommonModule,
    PluginRoutingModule,
    FormsModule, 
    ThemeModule,
    DragDropModule,
    NgbTooltipModule
  ]
})
export class PluginModule { }
