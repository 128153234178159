import { Component, Input, ElementRef, HostListener, Optional, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ControlBase } from '../control';

/**
 * <app-input [group]="userForm" controlName="firstName" id="id" type="text" size="sm" readonly="false"
 *    readonlyPlain="false" placeholder="First Name" requiredMsg="First Name is required">
 *    <ng-container label>First Name</ng-container>
 *   <ng-container hints>First Name Should be alphabets only.</ng-container>
 * </app-input>
 */
@Component({
  selector: 'app-input',
  templateUrl: 'input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlBase {
  @Input() group: UntypedFormGroup;
  @Input() controlName: UntypedFormControl;

  @Input() id: string;
  @Input() type: 'text' | 'email' | 'number' | 'phone' = 'text';
  @Input() size: 'lg' | 'sm';

  @Input() readonly: boolean;
  @Input() readonlyPlain: boolean;

  @Input() placeholder: string;
  @Input() requiredMsg: string;

  @Input() maxLength: number;
  @Input() minLength: number;

  @Input() max: number;
  @Input() min: number;

  @Input() icon: string;
  @Input() iconStyle: any;
  @Input() iconStatus = 'dark';

  @Input()
  omitSpecialCharacters = false;

  isVisible = false;


  @Output()
  iconEvent = new EventEmitter();

  errorMessage = '';

  constructor() {

  }


  get classes() {
    const classObj = {
      'form-control': true,
      'form-control-plaintext': this.readonlyPlain,
      'input-pl': this.icon,
      'text-white':true
    };
    if (this.size) {
      classObj['form-control-' + this.size] = true;
    }
    return classObj;
  }

  iconClick() {
    this.iconEvent.emit(true);
  }

  onlyNumber(event) {

    const reg = new RegExp('^[0-9]+$')
    if (!reg.test(event.key)) {
      event.preventDefault();
      return;

    }
    return;

  }
  omit_special_char(event) {

    if (this.type === "number") {
      return this.onlyNumber(event);
    }

    if (this.omitSpecialCharacters) {
      var message = 'Special characters are not allowed.';
      if (event.charCode) {
        let k;
        k = event.charCode;
        var valid = ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || k === 44 || k === 39 || k === 45);

        if (!valid) {
          this.isVisible = true;
          this.errorMessage = message
        } else {
          this.errorMessage = '';
          this.isVisible = false;
        }
        return valid;
      } else {
        let clipboardData = event.clipboardData;
        let clipboarText = clipboardData.getData('text');
        let i = 0;
        let valid = false;
        for (i = 0; i < clipboarText.length; i++) {
          let v = clipboarText.charCodeAt(i);
          valid = ((v > 64 && v < 91) || (v > 96 && v < 123) || v === 8 || v === 32 || (v >= 48 && v <= 57) || v === 44 || v === 39 || v === 45);
          if (!valid) {
            this.isVisible = true;
            this.errorMessage = message;
          } else {
            this.errorMessage = '';
            this.isVisible = false;
          }
        }
        return valid;

      }

    } else {
      return true;
    }
  }

  omit_input(event: any) {
    console.log(event.value);
  }
}
