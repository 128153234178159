import { Component, Input, ElementRef } from '@angular/core';
import { UntypedFormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls : []
})
export class CheckboxComponent {
  @Input() id: string;

  @Input() group: UntypedFormGroup;
  @Input() controlName: FormControlName;

  @Input() inline: boolean;
  @Input() value: any;

  constructor(protected el: ElementRef) { }
}
