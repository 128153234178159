import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input()
  svgName: string;

  @Input()
  svgSize = 50;

  @Input()
  svgStyle: any = {};

  @Input()
  status = 'dark';

  @Input()
  bg = 'white';

  get classes() {
    const classObj = {};
    classObj['icon-bg-' + this.bg] = true;
    classObj[this.status] = true;
    return classObj;
  }

  get svgStyles() {
    this.svgStyle['width.px'] = this.svgSize;
    this.svgStyle['height.px'] = this.svgSize;
    return this.svgStyle;
  }

  constructor() { }
}
