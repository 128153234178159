import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  public constructor() { }

  get(key) {
    const item = localStorage.getItem(key);
    return (item !== "undefined" && item !== null) ? JSON.parse(item) : null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }
}
