<ng-container cdkColumnDef>
    <th cdk-header-cell *cdkHeaderCellDef class="cursor-pointer">
        <span class="th-text" [ngbTooltip]="tooltip" (click)="onSortCol()"
            *ngIf="!isSearch ;else search">{{getTitle()}}</span>
        <ng-template #search>
            <span class="d-flex flex-column mt-12">
                <span class="th-text" [ngbTooltip]="tooltip" (click)="onSortCol()">{{getTitle()}}</span>
                <ng-container *ngIf="controlName; else space">
                    <input class="control mt-12 mb-12 pl-2" placeholder="Search..." type="text"
                        [formControl]="controlName" />
                </ng-container>
                <ng-template #space>
                    <span class="space mt-12 mb-12 pl-2 border-0"></span>
                </ng-template>
            </span>
        </ng-template>
    </th>
    <td cdk-cell *cdkCellDef="let data" [ngClass]="isActionCol? 'col-width': null">
        <ng-container *ngIf="!template">{{getData(data)}}</ng-container>
        <ng-container *ngTemplateOutlet="template; context: {$implicit: data}"></ng-container>
    </td>
</ng-container>