import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbInputDatepicker, NgbDateParserFormatter, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './date-picker.formatter';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
})
export class DatePickerComponent implements OnInit {
  @ViewChild('dp', { static: false }) dp: NgbInputDatepicker;

  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() id: string;
  @Input() placeholder: string;
  @Input() requiredMsg: string;

  today: NgbDate;

  constructor() {}

  ngOnInit() {
    this.updateMinDate();
    
    const control = this.group.get(this.controlName);
    control.statusChanges.subscribe(status => {
      this.updateMinDate();
    });
  }

  private updateMinDate() {
    const control = this.group.get(this.controlName);
    if (control.disabled) {
      this.today = null;
    } else {
      const now = new Date();
      this.today = new NgbDate(now.getFullYear(), now.getMonth() + 1, now.getDate());
    }
  }

  get classes() {
    return {
      'form-control': true,
    };
  }

  open() {
    const control = this.group.get(this.controlName);
    if (control.enabled) {
      this.dp.open();
    }
  }

  blur() {
    const control = this.group.get(this.controlName);
    if (control.invalid) {
      control.setValue(null);
    }
  }
}
