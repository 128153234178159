import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakAuthGuard } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.keycloakAngular
      .updateToken(120)
      .then(() => {
        if (!this.authenticated) {
          this.keycloakAngular.login().catch(e => console.error(e));
          return false;
        }
        const requiredRoles: string[] = route.data.roles;
        if (!requiredRoles || requiredRoles.length === 0) {
          return true;
        } else {
          if (!this.roles || this.roles.length === 0) {
            this.router.navigateByUrl('/'); // Redirect to the homepage
            return false;
          }
          const hasRequiredRole = requiredRoles.some(role => this.roles.indexOf(role) > -1);
          if (!hasRequiredRole) {
            this.router.navigateByUrl('/'); // Redirect to the homepage
          }
          return hasRequiredRole;
        }
      })
      .catch(err => {
        this.keycloakAngular.login().catch(e => console.error(e));
        this.router.navigateByUrl('/'); // Redirect to the homepage
        return false;
      });
  }
}
