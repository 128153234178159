import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublishService {
  private httpWihoutRequestInterceptor: HttpClient;

  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.httpWihoutRequestInterceptor = new HttpClient(handler);
  }
  getPluginDetail(id: any): Observable<any> {
    return this.http.get(`/plugins/${id}`);
  }
  getPlatforms() {
    return this.http.get('/plugins/data/platforms');
  }
  getDomains() {
    return this.http.get('/plugins/data/domains');
  }
  getTags(): Observable<any> {
    return this.http.get('/plugins/data/tags');
  }
  public getCompanies() {
    return this.http.get(`/plugins/data/companies`);
  }
  public getPublishData() {
    return this.http.get(`/plugins/data/publish`);
  }
  public getReviewURL() {
    return this.http.post(`/api/v3/reviews/categories/marketplace/items`, {});
  }
  public submitRequest(payload): Observable<any> {
    return this.http.post(`/plugins/`, payload);
  }
  public approveRequest(payload) {
    return this.http.put(`/plugins/${payload.id}`, payload);
  }
  public getPreSignedUploadUrl(folderName: string, fileName: string): any {
    return this.http.get<string>(`/plugins/data/s3/upload-url/${folderName}/${fileName}`, {
      responseType: 'text' as 'json',
    });
  }
  public uploadFile(s3SignedUrl: string, file) {
    const headers = new HttpHeaders().set('Content-Type', file.type);
    return this.httpWihoutRequestInterceptor.put(s3SignedUrl, file, { headers });
  }
}
