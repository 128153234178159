import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ɵConsole,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import 'rxjs/add/observable/of';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tag-input.component.scss'],
 })
export class TagInputComponent implements OnInit, AfterViewInit {

  @Input() group: UntypedFormGroup;
  @Input() controlName: UntypedFormControl;
  @Input() requiredMsg = '';
  @Input() id: string;
  
  @Input() charLimit: number = 25;
  @Input() maxItems: number = 5;
  @Input() autocompleteObservable: Observable<any>;
  @Input() tagsList = [];

  @Output() tagsChange = new EventEmitter<string[]>();
  @Input() values = [];
  el: ElementRef;  
  
  

  constructor(el: ElementRef) {
    this.el = el;
  }

  // usage example:
  ngOnInit() {}
  ngAfterViewInit() {
    const hostElem = this.el.nativeElement;
    const input = hostElem.querySelector('input[type=text]');
    input.setAttribute('maxlength', this.charLimit);
  }
  public requestAutocompleteItems = (text: any): Observable<any> => {
    return Observable.of(this.tagsList.map((t:any)=>t.tag));
  };
  tagsChanged($event) {
    this.tagsChange.emit($event);
  }
}
