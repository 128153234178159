import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCarousel } from './image-carousel.model';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit {
  @ViewChild(NgbCarousel) carousel: NgbCarousel;
  @ViewChild('imageModal') imageModal: any;
  modalImageUrl: string;

  @Input('images')
  set imagesValue(value) {
    this.images = value;
    if (this.images.length > 0) {
      this.currentImage = this.images[0];
      this.calculate();
    }
  }
  @Input() showNavigationIndicators = false;
  @Input() showNavigationArrows = true;
  @Input() showDetail = true;
  @Input() showFooter = true;
  @Input() displayZoom = true;

  images = [];
  sliders: ImageCarousel[] = [];
  currentSlide = 1;
  currentImage;
  imagesPerSlide = 7;
  numberOfSlides: number;

  constructor(private modalService: NgbModal, private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.currentImage == null && this.images.length > 0) {
      this.currentImage = this.images[0];
      this.calculate();
      this.displayZoom = false;
    }
  }

  moveToNextSlide() {
    if (this.currentSlide == this.numberOfSlides) return;

    const elec = document.getElementById(this.currentSlide.toString()) as HTMLElement;
    elec.classList.remove('active');

    this.currentSlide++;

    const elec2 = document.getElementById(this.currentSlide.toString()) as HTMLElement;
    elec2.classList.add('active');
  }

  moveToPreviousSlide() {
    if (this.currentSlide === 1) return;

    const elec = document.getElementById(this.currentSlide.toString()) as HTMLElement;
    elec.classList.remove('active');

    this.currentSlide--;

    const elec2 = document.getElementById(this.currentSlide.toString()) as HTMLElement;
    elec2.classList.add('active');
  }

  goToSlide(image) {
    this.currentImage = image;
    this.carousel.select(image.display_order);
  }

  calculate() {
    this.numberOfSlides =
      Math.floor(this.images.length / this.imagesPerSlide) === 0 && this.images.length > 0
        ? 1
        : Math.floor(this.images.length / this.imagesPerSlide) + 1;

    let imagesIndex = 0;

    for (let i = 0; i < this.numberOfSlides; i++) {
      const slider = new ImageCarousel();
      slider.slideIndex = i;
      for (let j = 0; j < this.imagesPerSlide; j++) {
        if (this.images[imagesIndex]) {
          slider.images.push(this.images[imagesIndex]);
          imagesIndex++;
        } else {
          break;
        }
      }
      this.sliders.push(slider);
    }
  }

  onSlide(slideDate) {
    this.currentImage = this.images.find(x => x.display_order == slideDate.current);
  }

  silderNavigatorDisabled(navigator: string) {
    if (navigator === 'right') {
      return this.numberOfSlides == this.currentSlide;
    } else {
      return this.currentSlide == 1;
    }
  }
  openImage(imageId: string) {
    const imageElement = document.getElementById(imageId) as HTMLImageElement;
    this.modalImageUrl = imageElement.src;
    const modalRef = this.modalService.open(this.imageModal, { centered: true });
    this.setModalSize(imageElement.naturalWidth, imageElement.naturalHeight);
  }

  private setModalSize(width: number, height: number) {
    const modalWidth = Math.min(90, width);
    const modalHeight = height;
    const modalDialog = document.querySelector('.modal-dialog');
    this.renderer.setStyle(modalDialog, 'max-width', `${modalWidth}vw`);
    this.renderer.setStyle(modalDialog, 'max-height', `${modalHeight}vh`);
    this.renderer.setStyle(modalDialog, 'display', 'flex');
    this.renderer.setStyle(modalDialog, 'justify-content', 'center');
  }
}
