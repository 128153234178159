import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@theme/services/toast.service';

@Component({
  selector: 'app-image-selection',
  templateUrl: './image-selection.component.html',
  styleUrls: ['./image-selection.component.scss'],
})
export class ImageSelectionComponent implements OnInit {
  @ViewChild('imageInformation') private imageInformation: TemplateRef<any>;
  @Input() form: FormGroup;

  private maxImagesLimit = 10;
  maxImageSize = 1 * 1024 * 1024; // 1MB in bytes
  private imageOrderCounter: number = 1;
  modalImage: FormGroup;
  constructor(private fb: FormBuilder, private modalService: NgbModal, private toastService: ToastService) {
    this.modalImage = this.fb.group({
      title: [''],
      description: [''],
      image_url: [''],
    });
  }

  ngOnInit(): void {}

  openFileSelector() {
    const fileInput = this.getFileInput();
    if (fileInput) {
      fileInput.setAttribute('multiple', 'multiple');
      fileInput.click();
    }
  }

  async handleFileSelect(event: any) {
    const files = event.target.files;

    const imagesFormArray = this.imagesFormArray;

    if (imagesFormArray.length >= this.maxImagesLimit) {
      this.toastService.error('Error', "You can't add more than 10 images.");
      event.target.value = '';
      return;
    }

    for (const file of files) {
      if (imagesFormArray.length >= this.maxImagesLimit) {
        this.toastService.error('Error', 'Maximum number of images reached (10).');
        break;
      }

      if (!this.isImageFile(file)) {
        this.toastService.error('Error', `File '${file.name}' is not a valid image.`);
        continue;
      }

      if (file.size > this.maxImageSize) {
        this.toastService.error('Error', `Image '${file.name}' size exceeds the 1MB limit.`);
        continue;
      }

      const dataURL = await this.convertFileToDataURL(file);
      const imageObject = this.createImageObject(file, dataURL);
      imagesFormArray.push(this.fb.group(imageObject));
      this.imageOrderCounter++;
    }
    event.target.value = '';
  }

  removeImage(index: number) {
    const imagesFormArray = this.imagesFormArray;
    imagesFormArray.removeAt(index);
    this.imageOrderCounter--;
  }

  private getFileInput(): HTMLInputElement | null {
    return document.getElementById('fileInput') as HTMLInputElement;
  }

  get imagesFormArray(): FormArray {
    return this.form.get('images') as FormArray;
  }

  private createImageObject(file: File, dataURL: string): any {
    const is_thumbnail = this.imageOrderCounter === 1;
    return {
      id: this.imageOrderCounter,
      image_url: dataURL,
      file: file,
      title: file.name,
      description: '',
      display_order: this.imageOrderCounter,
      is_thumbnail: is_thumbnail,
      is_mockup: false,
    };
  }

  private convertFileToDataURL(file: File): Promise<string> {
    return new Promise<string>(resolve => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  openImageDialog(imageIndex: number): void {
    const imageFormGroup = this.imagesFormArray.at(imageIndex);
    const { title, description, image_url } = imageFormGroup.value;

    this.modalImage.setValue({ title, description, image_url });

    const modalRef = this.modalService.open(this.imageInformation, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-with-animation',
      size: 'md',
      centered: true,
      animation: true,
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then(result => {
      if (result === 'submit') {
        const updateValues = {
          title: this.modalImage.get('title').value,
          description: this.modalImage.get('description').value,
        };
        imageFormGroup.patchValue(updateValues);
      }
    });
  }

  onDrop(event: CdkDragDrop<FormGroup[]>) {
    const imagesFormArray = this.imagesFormArray;
    const { previousIndex, currentIndex, item } = event;
    const control = imagesFormArray.at(previousIndex);

    imagesFormArray.removeAt(previousIndex);
    imagesFormArray.insert(currentIndex, control);

    imagesFormArray.controls.forEach((formGroup, index) => {
      formGroup.get('display_order').setValue(index + 1);
    });
  }

  setThumbnail(index: number) {
    const imagesFormArray = this.imagesFormArray;

    imagesFormArray.controls.forEach((control, i) => {
      control.get('is_thumbnail').setValue(i === index);
    });
  }
  private isImageFile(file: File): boolean {
    return file.type.startsWith('image/');
  }
}
