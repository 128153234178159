import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Subject, BehaviorSubject, combineLatest, of, Observable, Subscription } from 'rxjs';
import { IReader } from '@core/interfaces/reader.interface';

@Injectable()
export class DataSourceService {
  public renderData = new BehaviorSubject<any>([]);
  public count: Subject<any> = new BehaviorSubject<any>(0);

  private _data = new BehaviorSubject<any>([]);
  private _searchingStream = new BehaviorSubject<any>(null);
  private _filteringStream = new BehaviorSubject<any>(null);
  private _sortingStream = new BehaviorSubject<any>(null);
  private _navigationStream = new BehaviorSubject<any>(null);

  private _changesSubscription = Subscription.EMPTY;

  private _isSearchOrFilter = false;

  private unPaginatedData: any[] = [];

  constructor(private reader: IReader) {}

  connect() {
    if (this.reader.renderMode === 'client') {
      this._connectToClientSideDataSource();
    } else {
      this._connectToServerSideDataSource();
    }
  }

  reset() {
    this._searchingStream.next(null);
    this._filteringStream.next(null);
    this._sortingStream.next(null);
    this._navigationStream.next(null);
  }

  export(name: string, options: any) {
    // FileUtil.save(this.unPaginatedData, name, options);
  }

  get data() {
    return this._data.value;
  }

  set data(data: any) {
    this._data.next(data);
  }

  private _connectToClientSideDataSource() {
    this._changesSubscription.unsubscribe();

    const searchCheck$ = combineLatest([this._searchingStream]).pipe(
      switchMap(([data]) => {
        this._isSearchOrFilter = true;
        return of(data);
      })
    );

    const filterCheck$ = combineLatest([this._filteringStream]).pipe(
      switchMap(([data]) => {
        this._isSearchOrFilter = true;
        return of(data);
      })
    );

    const searchedData = combineLatest([this._data, searchCheck$]).pipe(
      switchMap(([data]) => {
        return this._clientSideSearchData(data);
      })
    );

    const filteredData = combineLatest([searchedData, filterCheck$]).pipe(
      switchMap(([data]) => {
        return this._clientSideFilterData(data);
      })
    );

    const sortedData = combineLatest([filteredData, this._sortingStream]).pipe(
      switchMap(([data]) => {
        return this._clientSideSortData(data);
      })
    );

    const paginatedData = combineLatest([sortedData, this._navigationStream]).pipe(
      switchMap(([data]) => {
        this.count.next(data.length);
        this.unPaginatedData = data;
        if (this._isSearchOrFilter && this._navigationStream.value && this._navigationStream.value.page) {
          this._isSearchOrFilter = false;
          const pageSize = this._navigationStream.value.size;
          const pageIndex = Math.min(this._navigationStream.value.page - 1, Math.ceil(data.length / pageSize) - 1);
          return of(data.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize));
        }
        return this._clientSidePageData(data);
      })
    );

    this._changesSubscription = paginatedData.subscribe(data => {
      this.renderData.next(data);
    });
  }

  private _connectToServerSideDataSource() {
    const paginatedData = combineLatest([
      this._filteringStream,
      this._searchingStream,
      this._sortingStream,
      this._navigationStream,
    ]).pipe(
      switchMap(([filteringCriteria, searchCriteria, sortCriteria, pageCriteria]) => {
        return this._serverSidePageData(filteringCriteria, searchCriteria, sortCriteria, pageCriteria);
      })
    );

    paginatedData.subscribe(data => {
      this.renderData.next(data);
    });
  }

  // this.dataSourceService.searchStream.next('abc');
  public get searchingStream(): Subject<{ value: string; searchFields: any }> {
    return this._searchingStream;
  }

  // this.dataSourceService.navigationStream.next({page: 1, size: 1});
  public get navigationStream(): Subject<{ page: number; size: number }> {
    return this._navigationStream;
  }

  // this.dataSourceService.filteringStream.next([{field: 'abc', value: 'abc'}]);
  public get filteringStream(): Subject<{ field: string; value: string }[]> {
    return this._filteringStream;
  }

  // this.dataSourceService.sortingStream.next({field: 'abc', order: 'desc'});
  public get sortingStream(): Subject<{ field: string; order: string }> {
    return this._sortingStream;
  }

  /**
   * Client Side Sort Data List
   */
  private _clientSideSortData(data: any[]): Observable<any[]> {
    if (this._sortingStream.value) {
      return of([...data.sort(this.reader.clientSideSortCompareFn(this._sortingStream.value))]);
    } else {
      return of(data);
    }
  }

  /**
   * Client Side Search Data List
   */
  private _clientSideSearchData(data: any[]): Observable<any[]> {
    if (this._searchingStream.value) {
      return of(data.filter(this.reader.clientSideSearchFn(this._searchingStream.value)));
    } else {
      return of(data);
    }
  }

  /**
   * Client Side Search Data List
   */
  private _clientSideFilterData(data: any[]): Observable<any[]> {
    if (this._filteringStream.value) {
      return of(data.filter(this.reader.clientSideFilterFn(this._filteringStream.value)));
    } else {
      return of(data);
    }
  }

  /**
   * Client Side Paginate Data List
   */
  private _clientSidePageData(data: any[]): Observable<any[]> {
    if (this._navigationStream.value && this._navigationStream.value.page) {
      const pageIndex = this._navigationStream.value.page - 1;
      const pageSize = this._navigationStream.value.size;
      return of(data.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize));
    } else {
      return of(data);
    }
  }

  /**
   *
   */
  private _serverSidePageData(
    filteringCriteria: any,
    searchCriteria: any,
    sortCriteria: any,
    pageCriteria: any
  ): Observable<any[]> {
    return this.reader.serverSidePageData(filteringCriteria, searchCriteria, sortCriteria, pageCriteria);
  }
}

export function DataSourceServiceProviderFactory(reader: any) {
  if (!(reader instanceof IReader)) {
    throw new Error(
      'DataSourceServiceProviderImpl expects "reader" argument to be type ' +
        `(instance) of IReader. But got ${reader.constructor.name}`
    );
  }
  return new DataSourceService(reader);
}
