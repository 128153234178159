import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { SidePanelDirection } from './models/side-panel.model';
import { SidePanelService } from './services/side-panel.service';
import { AuthService } from '@core/auth';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidePanelComponent implements OnInit {

  showSidePanel: Observable<boolean>;

  @Input() sidePanelTemplateRef: any;
  @Input() duration: number = 0.25;
  @Input() width: number = window.innerWidth;
  @Input() direction: SidePanelDirection = SidePanelDirection.Left;

  profile: any;

  constructor(private sidePanelService: SidePanelService,
    private authService: AuthService,
    public userService: UserService) { }

  ngOnInit(): void {
    this.showSidePanel = this.sidePanelService.getSidePanelVisibility();

  }

  onSidePanelClose() {
    this.sidePanelService.setSidePanelVisibility(false);
  }

  getShowPanelStyle(showSidePanel: boolean) {
    const sidePanelStyle: any = {};

    sidePanelStyle.transition = `${this.direction} ${this.duration}s, visibility ${this.duration}s`;
    sidePanelStyle.width = `${this.width}%`;
    sidePanelStyle[this.direction] = `${(showSidePanel ? 0 : (this.width * -1))}%`;

    return sidePanelStyle;
  }

  signOut() {
    this.authService.logout();
  }
}
