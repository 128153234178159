import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth';
import { AuthToken } from '@core/auth/models/token';
import { AppConfigurations } from '@core/models/app.configurations';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { interval, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable()
export class AppConfigurationsService {
  private configurations: AppConfigurations;
  userDetails: KeycloakProfile;

  constructor(private http: HttpClient,
    private authService: AuthService,
    private keycloakService: KeycloakService,
    private userService: UserService) {
  }

  get configs(): AppConfigurations {
    return this.configurations;
  }

  load() {
    // const solutionName = this.getQueryParameterByName('solutionName') || 'default';
    const solutionName = 'default';
    const promise = this.http
      .get("/useronboarding/configurations/default")
      .toPromise();

    promise.then((configs: AppConfigurations) => {
      this.configurations = configs
    });
    return promise;
  }

  loadSolutionConfig(solution, host) {
    const promise = this.http
      .get(`/users/configurations/appIntegration/configurations/${host}`)
      .toPromise();
    return promise;
  }
  initKeycloak() {
    const promise = this.keycloakService.init({ initOptions: { checkLoginIframe: false } });
    return promise;
  }

  currentUser() {
    const promise = this.keycloakService.isLoggedIn().then((loggedIn: boolean) => {
      if (loggedIn) {
        this.keycloakService.getToken().then(token => {
          const userInfo = new AuthToken(token);
          console.log('SET USER', userInfo);
          this.userService.setCurrentUserInfo(userInfo.getPayload());
          this.authService.setToken(token);
        });
        interval(180000).subscribe(val => {
          console.log('[KEYCLOAK]', 'Update Token Triggered');
          this.keycloakService.updateToken(120).catch(e => {
            console.log('[KEYCLOK]', 'Update Token Failed');
          });
        });
      }
    });
    return promise;
  }
  getQueryParameterByName(name) {
    // We are using this method because injecting activated Route here causes cyclic dependency.
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
}
