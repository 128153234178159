import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSourceService, DataSourceServiceProviderFactory } from '@core/services/data-source.service';
import { ToastService } from '@theme/services/toast.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { PluginRequest } from '../models/plugin-request.model';
import { ApprovalDataSource } from '../services/approval-data-source';
import { ApprovalService } from '../services/approval.service';

export const DTS_TOKEN = new InjectionToken<DataSourceService>('datasourceservice');

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss'],
  providers: [
    ApprovalDataSource,
    {
      provide: DTS_TOKEN,
      useFactory: DataSourceServiceProviderFactory,
      deps: [ApprovalDataSource],
    },
  ],
})
export class ApprovalComponent implements OnInit {
  private _input$ = new Subject<string>();
  loading = false;
  public requests;
  constructor(
    private router: Router,
    private approvalService: ApprovalService,
    private toastService: ToastService,
    @Inject(DTS_TOKEN) public dataSourceService: DataSourceService
  ) {}

  ngOnInit(): void {
    this.loadRequests();
    this.setSearchListener();
    this.dataSourceService.connect();
  }
  back(): void {
    this.router.navigateByUrl('/');
  }
  loadRequests() {
    this.loading = true;
    this.approvalService
      .getRequests()
      .pipe(
        map((res: any) => res.data),
        map((pluginsData: any[]) => pluginsData.map(plugin => new PluginRequest(plugin)))
      )
      .subscribe(
        (res: any) => {
          this.requests = res;
          this.dataSourceService.data = res;
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.toastService.error(
            'Error',
            'There was some error while loading requests. Please contact support if the problem persists.'
          );
        }
      );
  }
  setSearchListener() {
    this._input$.pipe(debounceTime(300), distinctUntilChanged()).subscribe((text: string) => {
      if (text.length) {
        this.dataSourceService.searchingStream.next({ value: text, searchFields: [] });
      } else {
        this.dataSourceService.searchingStream.next({ value: undefined, searchFields: [] });
      }
    });
  }
  onKeyup(event: any) {
    this._input$.next(event.target.value || '');
  }
}
