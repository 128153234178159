<div class="form-group position-relative" [formGroup]="group">
    <label [attr.for]="id">
      <ng-content select="[label]"></ng-content>
      <span class="text-danger" *ngIf="requiredMsg"> *</span>
    </label>
    <div class="note position-absolute" (click)="open()"><ng-content select="[note]"></ng-content></div>
    
    <input
      class="oec-datepicker"
      oecIsInvalid
      type="text"
      [ds365Placeholder]="placeholder"
      [ds365RequiredMsg]="requiredMsg"
      [name]="controlName"
      [formControlName]="controlName"
      ngbDatepicker
      #dp="ngbDatepicker"
      [minDate]="today"
      navigation="arrows"
      [attr.id]="id"
      [ngClass]="classes"
      [autoClose]="true"
      [dayTemplate]="customDay"
      (focus)="open()"
      (blur)="blur()"
      [restoreFocus]="false"
    />
  </div>
  
  <ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused"
  >
    <span
      class="custom-day font-weight-light"
      [class.focused]="focused"
      [class.bg-secondary]="selected"
      [class.text-white]="selected"
      [class.text-muted]="disabled"
    >
      {{ date.day }}
    </span>
  </ng-template>
  