import { CdkHeaderRowDef, CdkRowDef, CdkTable } from '@angular/cdk/table';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PageChangedEvent } from '../paginator/paginator.model';
import { DataTableColumnComponent } from './data-table-column/data-table-column.component';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewInit {
  @Input() columns!: any;
  @Input() searchForm: FormGroup;

  /** Toggles for pagination, sorting, and filtering */
  @Input() pagination: boolean = true;
  @Input() pageSize: number = 10;
  @Input() sortable: boolean = true;
  @Input() filterable: boolean = true;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() paginationPosition = 'start';
  @Output() onSort = new EventEmitter<any>();
  @Input() theme: string = 'default';
  /** Column definitions added via the DatatableColumn component */
  @ContentChildren(DataTableColumnComponent) datatableColumns!: QueryList<any>;

  /** References to the table and row definitions in this template */
  @ViewChild(CdkTable, { static: false }) table!: CdkTable<any>;
  @ViewChild(CdkHeaderRowDef, { static: false }) headerRows!: CdkHeaderRowDef;
  @ViewChildren(CdkRowDef) rows!: QueryList<any>;

  /** Paginator */

  /** The data resultant from the provided dataSource - this is fed directly into the table for display */
  // TODO: Remove this add only support dataSouce
  @Input() public data!: any;
  @Input() dataSource!: any;

  displayedColumns: any[] = [];
  count: Observable<any>;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.dataSource) {
      this.data = this.dataSource.renderData.asObservable();
      this.count = this.dataSource.count.asObservable();
    }
    this.displayedColumns = this.columns || this.datatableColumns.map(column => column.name);
    this.datatableColumns.forEach(datatableColumn => {
      this.table.addColumnDef(datatableColumn.columnDef);
      datatableColumn['onSort'] = this.onSort;
      if (this.searchForm != undefined) {
        datatableColumn['isSearch'] = true;
        if (this.searchForm.controls[datatableColumn.name]) {
          datatableColumn['controlName'] = this.searchForm.controls[datatableColumn.name];
        }
      }
    });
    this.cdRef.detectChanges();
  }

  navigatePage(e: PageChangedEvent) {
    if (this.dataSource) {
      this.dataSource.navigationStream.next({ page: e.currentPage, size: e.pageSize });
    }
  }
}
