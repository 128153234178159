import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { DS365Overlay } from '../overlay/overlay.service';
import { BehaviorSubject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoaderComponent } from './loader.component';

@Directive({
  selector: '[loader]',
})
export class LoaderDirective implements OnInit {
  @Input() level = 'root'; // Root or Child level
  private _loading = new BehaviorSubject<boolean>(false);
  private overlayRef: OverlayRef;

  @Input('loader')
  set loading(value) {
    this._loading.next(value);
  }

  get loading() {
    return this._loading.getValue();
  }

  constructor(private host: ElementRef, private ds365Overlay: DS365Overlay) { }

  ngOnInit() {
    if (this.level === 'root') {
      this.overlayRef = this.ds365Overlay.createWithDefaultConfig();
    } else {
      this.overlayRef = this.ds365Overlay.createWithDefaultConfig(
        this.host.nativeElement
      );
    }

    this._loading.subscribe(show => {
      if (show) {
        this.overlayRef.attach(new ComponentPortal(LoaderComponent));
      } else {
        this.overlayRef.detach();
      }
    });
  }
}
