import { CdkColumnDef, CdkTable } from '@angular/cdk/table';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { startCase } from 'lodash';
import { DatatableCellDirective } from './datatable-cell.directive';

@Component({
  selector: 'app-data-table-column',
  templateUrl: './data-table-column.component.html',
  styleUrls: ['./data-table-column.component.scss'],
})
export class DataTableColumnComponent implements OnInit, AfterViewInit, OnDestroy {
  _name!: string;
  @Input() label!: string;
  @Input() sort: boolean;
  @Input() onSort: EventEmitter<any>;
  @Input() isSearch: boolean = false;
  @Input() dataAccessor!: (data: any, name: string) => string;
  @Input() controlName: FormControl;
  @Input() tooltip: string;
  @ViewChild(CdkColumnDef, { static: true }) columnDef!: CdkColumnDef;
  @ContentChild(DatatableCellDirective, { static: true, read: TemplateRef }) template: any;
  @Input()
  get name(): string {
    return this._name;
  }
  set name(name: string) {
    this._name = name;
  }

  @Input() isActionCol = false;

  sortOrder: string = '';
  constructor(@Optional() public table: CdkTable<any>, private cdRef: ChangeDetectorRef) {}

  public getTitle(): string {
    return this.label || startCase(this.name);
  }

  public getData(data: any): any {
    return this.dataAccessor ? this.dataAccessor(data, this.name) : (data as any)[this.name];
  }
  ngOnInit() {
    if (this.table) {
      this.table.addColumnDef(this.columnDef);
      this.cdRef.detectChanges();
    }
  }
  ngAfterViewInit() {
    if (this.columnDef) this.columnDef.name = this.name;
  }
  onSortCol() {
    if (this.sort) {
      if (this.sortOrder == '') {
        this.sortOrder = 'asc';
      } else {
        if (this.sortOrder == 'asc') this.sortOrder = 'desc';
        else this.sortOrder = 'asc';
      }
      this.onSort.emit({ field: this.name, order: this.sortOrder });
    }
  }
  // sortData() {
  //   this.dataSourceService.sortingStream.next({ field: this.name, order: this.sortOrder });
  // }
  ngOnDestroy() {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
  }
}
