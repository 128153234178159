import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class HeaderService {
  constructor(private http: HttpClient) { }

  public appNavigations(type: string) {
    return this.http.get(`/useronboarding/configurations/default`);
  }
  public getNotifications(userId) {
     return this.http.get(`/notifications/public/user/${userId}`);
  }
  public addUserTimestamp(userId) {
   return this.http.post(`/notifications/public/user/${userId}/timestamp`, { userId });
  }
  public hideNotification(userId, notificationId) {
    return this.http.post(`/notifications/public/user/${userId}/notification/${notificationId}`, {});
  }
}
