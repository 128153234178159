<div class="main-container d-flex flex-column w-100">
  <app-header></app-header>
  <app-side-panel [sidePanelTemplateRef]="sidePanelContent" [direction]="'right'" [width]="20"
    [duration]="0.5"></app-side-panel>
  <div class="d-flex h-100 w-100">
    <!-- <div class="side-panel h-100"></div> -->
    <div class="h-100 w-100">
      <div class="h-100 w-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
 <app-toast></app-toast>
  <ng-template #sidePanelContent>
    Side Nav Bar Content Here
  </ng-template>
  <app-footer></app-footer>
</div>

