<header class="d-flex justify-content-between">


  <div class="d-flex flex-column menu-header ms-3 mt-2 align-self-center">
    <img alt="halliburton" src="/assets/images/halliburton-red.png">
    <!-- <div>
      <span class="heading">DecisionSpace®365 Plugin Exchange</span>
    </div> -->
  </div>

  <div class="align-self-center">
    <ul class="nav nav-pills me-2" *ngIf="isAuthenticated">
      <li class="nav-item">
        <app-icon svgName="user" svgSize="16" status="white" (click)="toggleSidePanel()" class="nav-link nav-padding">
        </app-icon>
      </li>
    </ul>
    <ul class="nav nav-pills me-2" *ngIf="!isAuthenticated">
      <li class="nav-item">
        <button type="button" class="btn" (click)="signIn()">Sign In</button>
      </li>
    </ul>
  </div>

</header>