<div
  class="custom-control custom-checkbox"
  [ngClass]="{ 'd-inline-flex': inline }"
  [formGroup]="group"
>
  <input
  [ngClass]="{ 'd-inline-flex align-items-center': inline }"
  class="mt-1"
    type="checkbox"
    [attr.id]="id"
    [value]="value"
    [formControlName]="controlName"
    ds365IsInvalid
  />
  <label class="custom-control-label ms-1" [attr.for]="id">
    <ng-content></ng-content>
  </label>
</div>
