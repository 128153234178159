import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards';
import { ApprovalComponent } from './approval/approval.component';
import { PublishComponent } from './publish/publish.component';

const routes: Routes = [
  {
    path: 'publish',
    component: PublishComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'publish/:id',
    component: PublishComponent,
    canActivate: [AuthGuard],
    data: { roles: ['plugin-reviewer', 'plugin-approver'] },
  },
  {
    path: 'approval',
    component: ApprovalComponent,
    canActivate: [AuthGuard],
    data: { roles: ['plugin-reviewer', 'plugin-approver'] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PluginRoutingModule {}
