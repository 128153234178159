import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbTooltipModule, NgbAccordionModule, NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputComponent } from '@theme/controls';
import { IsInvalidDirective, PlaceholderDirective } from './directives';
import { FooterModule } from './layouts';
import { IconModule } from './layouts/icon/icon.module';
import { LoaderComponent, LoaderDirective } from './layouts/loader';
import { IconTileComponent } from './layouts/icon-tile/icon-tile.component';
import { ɵb as UtilsService, ɵf as DomHelper } from 'ng2-date-picker';
import { CoreModule } from '@core/core.module';
import { HeaderModule } from './layouts/header/header.module';
import { ToastService } from './services/toast.service';
import { CheckboxComponent } from './controls/checkbox/checkbox.component';
import { SidePanelComponent } from './layouts/side-panel/side-panel.component';
import { ImageCarouselComponent } from './controls/image-carousel/image-carousel.component';
import { TextAreaComponent } from './controls/text-area/text-area.component';
import { ToastComponent } from './layouts/toast/toast.component';
import { SelectComponent } from './controls/select/select.component';
import { TagInputComponent } from './controls/tag-input/tag-input.component';
import { TagInputModule } from 'ngx-chips';
import { RadioGroupComponent } from './controls/radio-group/radio-group.component';
import { DatePickerComponent } from './controls/date-picker/date-picker.component';
import { TypeaheadComponent } from './controls/typeahead/typeahead.component';
import { DataTableComponent } from './layouts/data-table/data-table.component';
import { DataTableColumnComponent } from './layouts/data-table/data-table-column/data-table-column.component';
import { PaginatorComponent } from './layouts/paginator/paginator.component';
import { CdkTableModule } from '@angular/cdk/table';
import { DatatableCellDirective } from './layouts/data-table/data-table-column/datatable-cell.directive';

const MODULES = [
  CoreModule,
  HeaderModule,
  FooterModule,
  IconModule,
  NgbAccordionModule,
  NgbCarouselModule,
  NgbModule,
  TagInputModule,
  CdkTableModule,
];
const COMPONENTS = [
  InputComponent,
  LoaderComponent,
  LoaderDirective,
  PlaceholderDirective,
  IsInvalidDirective,
  IconTileComponent,
  CheckboxComponent,
  IconTileComponent,
  SidePanelComponent,
  ImageCarouselComponent,
  TextAreaComponent,
  ToastComponent,
  SelectComponent,
  TagInputComponent,
  RadioGroupComponent,
  DatePickerComponent,
  TypeaheadComponent,
  PaginatorComponent,
  DataTableComponent,
  DataTableColumnComponent,
  DatatableCellDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    SelectComponent,
    TagInputComponent,
    RadioGroupComponent,
    DatePickerComponent,
    TypeaheadComponent,
    DataTableComponent,
    DataTableColumnComponent,
    PaginatorComponent,
  ],
  imports: [CommonModule, ...MODULES],
  exports: [...COMPONENTS, ...MODULES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [UtilsService, DomHelper],
    } as ModuleWithProviders<ThemeModule>;
  }
}
