import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { Alert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<Alert | boolean>();
  private sticky = false;

  constructor(private router: Router) {
    const sub = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.sticky) {
          this.sticky = false;
        } else {
          this.clear();
        }
      }
      sub.unsubscribe();
    });
  }

  onAlert(): Observable<Alert | boolean> {
    return this.subject.asObservable();
  }

  success(key?: string) {
    this.alert(new Alert({ type: AlertType.Success, key }));
  }

  error(key?: string) {
    this.alert(new Alert({ type: AlertType.Error, key }));
  }

  info(key?: string) {
    this.alert(new Alert({ type: AlertType.Info, key }));
  }

  warn(key?: string) {
    this.alert(new Alert({ type: AlertType.Warning, key }));
  }

  alert(alert: Alert) {
    this.sticky = alert.sticky;
    this.subject.next(alert);
  }

  clear() {
    this.subject.next(false);
  }
}
