import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ResolveStart, ResolveEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  logoPath = '/assets/images/logo.svg';
  title = 'application';
  loading = false;
  theme = 'red';
  isSignupPage = false;
  constructor(private router: Router,
    private location: Location) {
    // Show loading when resolve project for route
    this.router.events.subscribe(event => {
      if (event instanceof ResolveStart) {
        this.loading = true;
      } else if (event instanceof ResolveEnd) {
        this.loading = false;
      }
    });

    router.events.subscribe(val => {
      if (this.location.path().indexOf('/auth/signup') > -1) {
        this.isSignupPage = true;
      }
    });
  }
}
