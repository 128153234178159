import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard, NoAuthGuard } from '@core/auth/guards';
import { RedirectGuard } from '@core/auth/guards/landingpage-redirect.guard';
import { RequestInterceptor } from '@core/auth/interceptors/request-interceptor';
import { CoreModule } from '@core/core.module';
import { AlertModule } from '@theme/layouts/alert/alert.module';
import { ThemeModule } from '@theme/theme.module';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PluginModule } from './modules/plugin/plugin.module';

const keycloakService = new KeycloakService();

const MODULES = [CoreModule.forRoot(), ThemeModule.forRoot(), AlertModule, AppRoutingModule, KeycloakAngularModule, PluginModule];

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserAnimationsModule, ...MODULES],
  bootstrap: [AppComponent],
  providers: [
    AuthGuard,
    RedirectGuard,
    NoAuthGuard,
    {
      provide: KeycloakService,
      useValue: keycloakService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
})
export class AppModule { }
