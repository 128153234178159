<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  class="toast-dim"
  [autohide]="toast.autohide"
  [delay]="toast.delay"
  (hidden)="toastService.remove(toast.id)"
>
  <div *ngIf="toast" class="d-flex">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center">
        <app-icon *ngIf="toast.icon" svgSize="18" [svgName]="toast.iconname" class="mb-1"></app-icon>
        <div class="title ms-3">{{ toast.title }}</div>
      </div>
      <div class="mt-2">
        <ng-container *ngIf="isTemplate(toast); else text">
          <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-container>
        <ng-template #text>
          <span >{{ toast.textOrTpl }}</span>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="toast.dismissable"
      class="d-flex justify-content-end cursor-pointer flex-grow-1"
      data-dismiss="alert"
      aria-label="Close"
      (click)="toastService.remove(toast.id)"
    >
    <app-icon *ngIf="toast.icon" svgSize="14"  svgName="times"></app-icon>
    </div>
  </div>
</ngb-toast>
