<div class="d-flex align-items-center">
  <div class="flex-grow-1">
    <span class="work-thumbnail">Plugin Images</span>
  </div>
  <div class="flex-grow-1 d-flex justify-content-end">
    <!-- Input element to trigger file selection -->
    <input type="file" id="fileInput" style="display: none;" (change)="handleFileSelect($event)" accept="image/*" />
    <button class="btn-submit" (click)="openFileSelector()">
      <app-icon class="icon mb-1" svgName="upload" svgSize="14" bg="black"></app-icon>
      <span class="ps-1 pe-1">Upload Images</span>
    </button>
  </div>
</div>
<div class="mt-3 h-40">
  <div class="image-box" style="height: 370px;"
    [ngClass]="imagesFormArray.length === 0 ? 'd-flex align-items-center justify-content-center' : ''">
    <app-icon *ngIf="imagesFormArray.length === 0" class="icon mb-1" svgName="image-icon" svgSize="116" bg="black">
    </app-icon>
    <app-image-carousel *ngIf="imagesFormArray.length > 0" [images]="imagesFormArray['value']"
      [showNavigationArrows]="true" [showNavigationIndicators]="true" [showDetail]="false" [showFooter]="false"
      class="image"></app-image-carousel>
  </div>

  <div class="note mt-3" *ngIf="imagesFormArray?.length > 0">
    You can customize the title, description, and sequence of images below.
    Simply drag and drop the images to modify their order of display.
    Please note that you can upload a maximum of 10 images,
    and each image must be 1MB or smaller in size.
  </div>

  <div class="table-container mt-3" *ngIf="imagesFormArray?.length > 0">
    <table>
      <thead class="sticky-header">
        <tr>
          <th class="table-title">Title</th>
          <th class="desc">Description</th>
          <th class="thumbnail">Set as Thumbnail</th>
          <th class="thumbnail">Mockup</th>
          <th class="action">Action</th>
        </tr>
      </thead>
      <tbody cdkDropList [cdkDropListData]="imagesFormArray.controls" (cdkDropListDropped)="onDrop($event)">
        <tr *ngFor="let image of imagesFormArray.controls; let i = index" cdkDrag [cdkDragData]="image"
          class="cdk-drag-placeholder cdk-drag-preview">
          <td class="table-title d-flex w-100 align-items-center">
            <div class="flex-grow-0">
              <img [src]="image.get('image_url').value" alt="Thumbnail" width="50" height="50" />
            </div>
            <div class="flex-grow-1 ms-2">
              <span [ngbTooltip]="image.get('title').value" [title]="image.get('title').value"
                [class.limit-text]="image.get('title').value.length > 20">
                {{ image.get('title').value | slice:0:20 }}
                <span *ngIf="image.get('title').value.length > 20">...</span>
              </span>
            </div>
          </td>
          <td class="desc">
            <span [ngbTooltip]="image.get('description').value" [title]="image.get('description').value"
              [class.limit-text]="image.get('description').value.length > 75">
              {{ image.get('description').value | slice:0:75 }}
              <span *ngIf="image.get('description').value.length > 75">...</span>
            </span>
          </td>         
          <td class="thumbnail text-center align-middle">
            <app-checkbox [id]="'thumbnail_' + i" [value]="image.get('is_thumbnail').value" [group]="image"
              controlName="is_thumbnail" [inline]="true" (click)="setThumbnail(i)">
            </app-checkbox>
          </td>
          <td class="thumbnail text-center align-middle">
            <app-checkbox [id]="'mockup_' + i" [value]="image.get('is_mockup').value" [group]="image"
              controlName="is_mockup" [inline]="true">
            </app-checkbox>
          </td>
          <td class="action text-center align-middle">
            <app-icon class="icon me-3 cursor-pointer" svgName="edit" svgSize="14" bg="black"
              (click)="openImageDialog(i)"></app-icon>
            <app-icon class="icon mb-1 cursor-pointer" svgName="delete" svgSize="14" bg="black"
              (click)="removeImage(i)"></app-icon>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
</div>

<ng-template #imageInformation let-modal>
  <div class="modal-header pl-30">
    <h4 class="modal-title" id="modal-basic-title">Information for Images</h4>
    <button type="button" class="close cursor-pointer" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-2rem">
    <form [formGroup]="modalImage">
      <!-- Image URL Input -->
      <img [src]="modalImage.get('image_url').value" width="100%" height="250" />

      <div class="mt-2">
        <app-input [id]="'title'" [group]="modalImage" controlName="title" [maxLength]="64" placeholder="Image title">
          <span class="label-default" label>Title</span>
        </app-input>
      </div>
      <div class="mt-2">
        <app-text-area [id]="'description'" [group]="modalImage" controlName="description" [maxLength]="1024"
          placeholder="" [styles]="{ height: '88%' }">
          <span class="label-default" label>Description</span>
        </app-text-area>
      </div>
    </form>

    <div class="d-flex justify-content-end mt-3">
      <button class="btn-secondary me-3" (click)="modal.dismiss()">Cancel</button>
      <button class="btn-primary cursor-pointer" (click)="modal.close('submit')">
        Submit
      </button>
    </div>
  </div>
</ng-template>