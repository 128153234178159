<div class="d-flex">
  <div class="flex-grow-1">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger" *ngIf="requiredMsg"> *</span>
  </div>
  <div class="flex-grow-1 note d-flex justify-content-end text-muted me-1">
    <ng-content select="[note]"></ng-content>
  </div>
</div>


<tag-input #tagInput [blinkIfDupe]="true" [separatorKeyCodes]="[188, 13, 39]" (ngModelChange)="tagsChanged($event)"
  [separatorKeyCodes]="[188, 13, 39]" [maxItems]="5" theme="bootstrap" [addOnBlur]="true" [inputClass]="'tag'"
  [(ngModel)]="values">
  <ng-template let-item="item" let-index="index">
    <span class=" d-inline-block item mx-2">
      {{ item.display }}
    </span>
    <app-icon class="icon" svgName="close-circle" svgSize="14" bg="black" fill="none"
      (click)="tagInput.removeItem(item, index)"></app-icon>
  </ng-template>
  <tag-input-dropdown [limitItemsTo]="5" [showDropdownIfEmpty]="false">
  </tag-input-dropdown>
</tag-input>