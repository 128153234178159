export class PluginRequest {
  submitter: string;
  isPublished: boolean;
  relatedProject: any;
  projectStatus: any;
  reviewUrl: string;
  description: string;
  remark: any;
  thumbnailUrl: any;
  title: string;
  type: string;
  namespaceName: any;
  artifactoryUrl: any;
  contributor: string;
  updateUserId: string;
  projectId: number;
  projectOwner: any;
  projectCreateDate: any;
  bannerImageUrl: any;
  platformName: string;
  pluginStatus: string;
  id: number;
  deploymentId: string;
  createDate: string;
  gitUrl: any;
  owner: string;
  projectAccess: any;
  createUserId: string;
  projectType: any;
  visibility: string;
  domains: any[];
  updateDate: string;
  tags: any[];
  uniqueName: any;
  platformCode: string;
  projectDescription: any;
  stage: string;
  imgUrl: any;
  releaseDate: any;
  processInstanceId: number;
  documentUrl: any;
  domainsArray: string;

  constructor(data: any) {
    this.submitter = data.submitter;
    this.isPublished = data.is_published;
    this.relatedProject = data.related_project;
    this.projectStatus = data.project_status;
    this.reviewUrl = data.review_url;
    this.description = data.description;
    this.remark = data.remark;
    this.thumbnailUrl = data.thumbnail_url;
    this.title = data.title;
    this.type = data.type;
    this.namespaceName = data.namespace_name;
    this.artifactoryUrl = data.artifactory_url;
    this.contributor = data.contributor;
    this.updateUserId = data.update_user_id;
    this.projectId = data.project_id;
    this.projectOwner = data.project_owner;
    this.projectCreateDate = data.project_create_date;
    this.bannerImageUrl = data.banner_image_url;
    this.platformName = data.platform_name;
    this.pluginStatus = data.plugin_status;
    this.id = data.id;
    this.deploymentId = data.deployment_id;
    this.createDate = data.create_date;
    this.gitUrl = data.git_url;
    this.owner = data.owner;
    this.projectAccess = data.project_access;
    this.createUserId = data.create_user_id;
    this.projectType = data.project_type;
    this.visibility = data.visibility;
    this.domains = data.domains;
    this.updateDate = data.update_date;
    this.tags = data.tags;
    this.uniqueName = data.unique_name;
    this.platformCode = data.platform_code;
    this.projectDescription = data.project_description;
    this.stage = data.stage;
    this.imgUrl = data.img_url;
    this.releaseDate = data.release_date;
    this.processInstanceId = data.process_instance_id;
    this.documentUrl = data.document_url;
    this.domainsArray = this.parseJson(data.domains);
  }
  private parseJson(data: any): any {
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        return [];
      }
    }
    return '';
  }
}
