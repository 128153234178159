import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-icon-tile',
  templateUrl: './icon-tile.component.html',
  styleUrls: ['./icon-tile.component.scss'],
})
export class IconTileComponent implements OnInit {

  @Input()
  bgColor = 'transparent';

  @Input()
  sharp: 'square' | 'rect' = 'square';

  @Input()
  disabled = false;

  @Output()
  clicked: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  onClick(event) {
    event.stopPropagation();
    this.clicked.emit();
  }

}
