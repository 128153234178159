<div class="table-responsive table-grid" [class.border]="theme=='light'">
    <table class="w-100" cdk-table [dataSource]="data">
        <thead class="table-head">
            <tr class="table-head-row" cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        </thead>
        <tbody class="section">
            <tr [class.even]="theme=='light' && even"
                [ngClass]="theme=='default'?'table-data-default':'table-data-light'" cdk-row
                *cdkRowDef="let row; let even = even; columns: displayedColumns"></tr>
        </tbody>
    </table>

    <div *ngIf="(data && data.length === 0) || (dataSource && dataSource.renderData?.value?.length==0)"
        class="empty_row px-10">
        <ng-content select="[noData]"></ng-content>
    </div>
</div>
<ng-content select="[note]"></ng-content>
<ng-container *ngIf="data && pagination">
    <app-paginator [count]="count | async" [position]="paginationPosition" (pageChanged)="navigatePage($event)"
        [defaultPageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></app-paginator>
</ng-container>