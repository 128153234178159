<div class="form-group position-relative" [formGroup]="group">
  <label [attr.for]="id">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger" *ngIf="requiredMsg"> *</span>
  </label>
  <div class="note position-absolute"><ng-content select="[note]"></ng-content></div>
  <span class="input-icon" *ngIf="icon" (click)="iconClick()">
    <app-icon *ngIf="iconStyle" [svgName]="icon" svgSize="18" [svgStyle]="iconStyle" [status]="iconStatus"></app-icon>
    <app-icon *ngIf="!iconStyle" [svgName]="icon" svgSize="18" [status]="iconStatus"></app-icon>
  </span>
  <input [readonly]="readonly" [ds365Placeholder]="placeholder" [ds365RequiredMsg]="requiredMsg" ds365IsInvalid
    [name]="controlName" [attr.type]="type" [ngClass]="classes" [attr.id]="id" [formControlName]="controlName"
    [attr.maxlength]="maxLength ? maxLength : null" [attr.minlength]="minLength ? minLength : null"
    [attr.max]="max ? max : null" [attr.min]="min ? min : null" (keypress)="omit_special_char($event)"
    (paste)="omit_special_char($event)" (keyup)="omit_input($event)" />
  <small [style.display]="isVisible ? 'block' : 'none'" class="mt-2 form-text text-light">&nbsp;
    <ng-content select="[hints]"></ng-content>
    <span class="text-danger" *ngIf="errorMessage">{{errorMessage}}</span>
  </small>
</div>