import { Injectable } from '@angular/core';
import { of, Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { switchMap, shareReplay, map, pluck, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private cache$: any = {};

  public isUserApproved: boolean;
  private userKey = 'user-info';

  private currentUserSource = new ReplaySubject<any>(1);
  currentUser$ = this.currentUserSource.asObservable();
  user: any;
  initials: string;
  constructor(private http: HttpClient, private storageService: StorageService) {
    this.setCurrentUserInfo();
  }

  public searchUserByKeyword(keyword: string, status: string): Observable<any[]> {
    if (!keyword.trim()) {
      return of([]);
    }
    return this.http.get<any[]>(`/users/identities/search?keyword=${keyword}&statusFilter=${status}`);
  }

  public getProfile(email?: string): Observable<any> {
    if (!this.cache$.getProfile) {
      this.cache$.getProfile = this.http.get(`/api/users/summary?keyword=${email}`).pipe(shareReplay(1));
    }
    return this.cache$.getProfile;
  }

  public getAccountManagers() {
    return this.http.get(`users/managers`);
  }

  public getApprovedUsers() {
    return this.http.get(`users/?status=approved`);
  }

  public getTerms() {
    return this.http.get(`/terms/`).pipe(map((data: any) => data.terms));
  }

  public termsAccepted() {
    return this.http.get(`/api/users/terms/accepted`, { observe: 'response' }).pipe(
      map((response: any) => {
        return { isError: !response.body, status: response.statusCode || response.status };
      }),
      catchError(error => {
        return of({ isError: true, status: error.statusCode || error.status });
      })
    );
  }

  public acceptUpdatedTerms(terms) {
    return this.http.post(`/users/terms`, terms);
  }

  public getUserAttribute(key) {
    return this.http.get(`users/attributes/${key}`);
  }

  public saveUserAttribute(payload) {
    return this.http.post(`users/attributes`, payload);
  }

  public getUserOktaProfile() {
    return this.http.get(`users/okta`);
  }

  setCurrentUserInfo(user?: any) {
    const userInfo = this.storageService.get(this.userKey);
    if (userInfo) {
      this.currentUserSource.next(userInfo);
      this.user = userInfo;
      this.initials = userInfo ? userInfo?.given_name.substring(0, 1) + userInfo?.family_name.substring(0, 1) : '';
    } else {
      this.currentUserSource.next(user);
      this.user = user;
      this.initials = user ? user?.given_name.substring(0, 1) + user?.family_name.substring(0, 1) : '';
      this.storageService.set(this.userKey, user);
    }
  }

  getCurrentUserInfo(): Observable<any> {
    return this.currentUser$;
  }

  clear() {
    this.storageService.remove(this.userKey);
  }

  sendToken(payload) {
    return this.http.post(`/userrequests/validationtokens`, payload);
  }
  validateToken(payload) {
    return this.http.post(`/userrequests/validate/token`, payload);
  }
}
