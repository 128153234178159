import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get(`/companies/`);
  }

  getCompanyByName(name: string) {
    return this.http.get(`/plugins/data/companies/${name}`);
  }

  getCompanyUsers(id) {
    return this.http.get(`/companies/${id}/users`);
  }

  getCompanyManagers() {
    return this.http.get(`/companies/managers`);
  }

  updateRepresentative(companyId, userId, status) {
    return this.http.put(`/companies/${companyId}/users/${userId}`, { is_company_representative: status });
  }

  updateCompanyManagers(companyId, managers) {
    return this.http.put(`/companies/${companyId}/managers`, managers);
  }

  updateHalRegions(companyId, payload) {
    return this.http.put(`/companies/${companyId}/halregions`, payload);
  }

  getPackages() {
    return this.http.get('/clusters/products');
  }

  getHalRegions() {
    return this.http.get(`/companies/halregion/all/getall`);
  }

  removeCompany(companyID: number) {
    return this.http.delete('/companies/' + companyID).pipe(map(results => true));
  }
}
