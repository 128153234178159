import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionItem } from './selection-item';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  @ViewChild('search', { static: false }) searchElement: ElementRef;

  @Input() fg: FormGroup;
  @Input() control: FormControl;
  @Input() id: string;
  @Input() class: string;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() optKey = 'id';
  @Input() optString = 'title';
  @Input() items: any[];
  @Input() isRequired = false;
  @Input() styles = {};
  @Input() highlight = false;
  @Input() theme = 'dark';
  @Input() size = 'small';

  @Input() stickyOpt = false;
  @Input() multiple = false;

  @Input() noneSelectedText = 'Nothing selected';
  @Input() noneResultsText = 'No results matched ';
  @Input() multipleSeparator = ', ';
  @Input() liveSearch = false;
  @Input() showOptionsOnSearch = false;
  @Input() liveSearchPlaceholder: any = null;
  @Input() liveSearchStyle: 'contains' | 'startsWith' = 'contains';
  @Input() maxOptions = 100;
  @Input() searchWithOptKey = null;
  filteredItems: any[];
  @Output()
  change: EventEmitter<any> = new EventEmitter();
  open = false;
  value: any;
  selection: string;
  query: FormControl;

  private clonedItems: SelectionItem[];

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.el && this.el.nativeElement && !this.el.nativeElement.contains(event.target)) {
      if (this.open) {
        this.open = false;
      }
    }
  }
  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    this.query = new FormControl();

    this.query.valueChanges.subscribe(filter => {
      this._filterResults(filter);
    });
    if (this.control) {
      this.control.valueChanges.subscribe(value => {
        if (value === null) {
          this.selection = this.noneSelectedText;
          this.query.reset();
          this.filteredItems.forEach(item => (item.selected = false));
          this.clonedItems.forEach(item => (item.selected = false));
        }
      });
    }
    this._updateValue();
  }
  public ngOnChanges(changes: { [propertyName: string]: any }) {
    const that = this;

    if (changes.hasOwnProperty('items')) {
      let clone: SelectionItem[] = [];

      const currentValue = changes.items.currentValue;
      if (currentValue) {
        clone = currentValue.map((item: any) => ({
          id: item[this.optKey],
          title: item[this.optString],
          disabled: item.disabled,
          selected: item.selected,
          ignoreSearch: item.ignoreSearch,
          divider: item.divider,
        }));
      }

      this.filteredItems = clone;
      this.clonedItems = clone;
      this._updateSelectionText();
      this._updateValue();
      this._filterResults(null);
    }
  }

  get inPutstyles() {
    if (this.control && this.control.value === '') {
      return Object.assign(this.styles, { color: '#828282' });
    } else {
      return Object.assign(this.styles, { color: '#fff' });
    }
  }
  get emptySearch(): boolean {
    return this.filteredItems === undefined || this.filteredItems.length === 0;
  }

  get isInvalid(): boolean {
    return this.control && this.control.invalid && (this.control.dirty || this.control.touched);
  }

  toggleOpen() {
    if (!this.disabled) {
      this.open = !this.open;
      if (this.open) {
        this.query.reset();
        setTimeout(() => this.searchElement.nativeElement.focus(), 0);
      } else {
        setTimeout(() => this.searchElement.nativeElement.blur(), 0);
      }
    }
  }

  toggleItem(item: SelectionItem) {
    if (!this.multiple) {
      this.clonedItems.forEach(o => {
        o.selected = false;
      });

      item.selected = !item.selected;
    } else {
      this._processMultipleSelection(item);
    }

    this._updateSelectionText();
    this._updateValue();
    if (!this.multiple) this.toggleOpen();
    this.change.emit(item);
  }

  public addValidators(): void {}

  private _updateValue() {
    const selectedItems = this.clonedItems.filter(item => item.selected).map(item => item.id);
    const values = selectedItems.join(',');
    if (this.control) this.control.setValue(values);
    this.value = values;
  }

  private _updateSelectionText() {
    if (this.clonedItems) {
      this.selection = this.clonedItems
        .filter(item => item.selected)
        .map(item => item.title)
        .join(this.multipleSeparator);
    }

    if (!this.selection) {
      this.selection = this.noneSelectedText;
    }
  }

  private _filterResults(filter: any) {
    const contains = this.liveSearchStyle === 'contains';
    const selectedItems = this.clonedItems.filter(o => o.selected);
    if (!filter || filter === '') {
      this.filteredItems = !this.showOptionsOnSearch ? this.clonedItems : [...selectedItems];
    } else {
      this.filteredItems = this.clonedItems.filter(item => {
        if (item.ignoreSearch) {
          return true;
        }
        const searchKey = this.searchWithOptKey ? item.id : item.title;
        return contains
          ? searchKey
              ?.toString()
              .toLowerCase()
              .includes(filter.toLowerCase())
          : searchKey
              ?.toString()
              .toLowerCase()
              .startsWith(filter.toLowerCase());
      });
    }
  }

  private _processMultipleSelection(item: SelectionItem) {
    const selectedItems = this.clonedItems.filter(o => o.selected);
    // only select the items if we are under the max options
    if (!item.selected && selectedItems.length >= this.maxOptions) {
      return;
    }
    item.selected = !item.selected;
  }
}
