<section class="w-100 h-100">
  <div class="w-100 h-100">
    <ng-container class="d-flex flex-column" *ngIf="images.length > 0; else noPreview">
      <div class="img-container bg-dark">
        <ngb-carousel [showNavigationIndicators]="showNavigationIndicators"
          [showNavigationArrows]="showNavigationArrows" (slide)="onSlide($event)" class="h-100">
          <ng-template ngbSlide [id]="image?.display_order" *ngFor="let image of images">
            <div class="picsum-img-wrapper">
              <img [src]="image.image_url" [alt]="image.title" [id]="image.id" (click)="openImage(image.id)"
                class="cursor-pointer" />
            </div>
          </ng-template>
        </ngb-carousel>
        <ng-container *ngIf="showDetail">
          <div class="d-flex justify-content-between" style="height:88px">
            <div style=" width: 5%; align-items: center;" class="d-flex justify-content-center">
              <img class="thin_arrows" src="assets/images/left_arrow_thin.svg" (click)="moveToPreviousSlide()"
                [ngClass]="silderNavigatorDisabled('left') ? 'disabled-arrow' : ''" />
            </div>

            <div style="width: 90%;">
              <div class="img-thumbnail-container text-center">
                <div class="carousel slide w-100 bg-dark" data-ride="carousel">
                  <div class="carousel-inner w-100" role="listbox">
                    <ng-container *ngFor="let slide of sliders; let i = index">
                      <div class="carousel-item row g-0" [ngClass]="currentSlide == i + 1 ? 'active' : ''" [id]="i + 1">
                        <div class="col-3 float-start img-slide"
                          [ngClass]="currentImage?.display_order == image.display_order ? 'thumnail-active' : ''"
                          *ngFor="let image of slide.images; let j = index">
                          <img [src]="image.image_url" (click)="goToSlide(image)"
                            style="max-width: 80px; max-height: 60px; object-fit: fill;" />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 5%; align-items: center;" class="d-flex justify-content-center">
              <img class="thin_arrows" src="assets/images/right_arrow_thin.svg" (click)="moveToNextSlide()"
                [ngClass]="silderNavigatorDisabled('right') ? 'disabled-arrow' : ''" />
            </div>
          </div>
        </ng-container>
        <div class="carousel-footer d-flex ms-1 me-1 p-1" *ngIf="showFooter">
          <div class="flex-grow-1 ms-1">
            <span class="info-bold">{{ currentImage?.display_order || '' }} </span><span class="info">/{{ images.length
              || '' }}</span>
            <span class="info-bold ms-3">{{ currentImage?.title || '' }}</span>
            <span class="info ms-1">{{ currentImage?.description || '' }}</span>
          </div>
          <div class="flex-grow-1 d-flex justify-content-end me-1">
            <img src="assets/images/zoom-in.svg" alt="images list" class="thin_arrows"
              (click)="openImage(currentImage.id)" *ngIf="displayZoom" />
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noPreview>
      <div class="d-flex flex-column justify-content-center" style="align-items: center; margin-top: 300px;">
        <img src="assets/images/no-preview.svg" />
        <span class="no-preview mt-2">No preview available</span>
      </div>
    </ng-template>
  </div>
</section>
<ng-template #imageModal let-modal>
  <div class="modal-body">
    <img [src]="modalImageUrl" class="img-fluid" alt="Image">
  </div>
</ng-template>