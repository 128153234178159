import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NoAuthGuard } from '@core/auth/guards';
import { RedirectGuard } from '@core/auth/guards/landingpage-redirect.guard';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./modules/marketplace/marketplace.module').then(m => m.MarketplaceModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
