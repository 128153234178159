<div class="form-group" [formGroup]="fg">
  <label [attr.for]="id">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger" *ngIf="isRequired"> *</span>
  </label>
  <div [attr.id]="id" [ngClass]="{ 'border border-danger': highlight }" class="btn-group ds365-select w-100"
    [class.disabled]="disabled" [class.open]="open" [ngClass]="{ 'search-select': liveSearch }">
    <button type="button" class="btn dropdown-toggle btn-selectpicker d-flex align-items-center dark small"
      [ngClass]="{ light: theme === 'light', large: size === 'large', 'open-border': open}" [ds365IsInvalid]="control"
      [ngStyle]="styles" (click)="toggleOpen()" data-toggle="dropdown" role="button" data-id="company"
      [attr.aria-disabled]="disabled">
      <span class="filter-option text-truncate default"
        [ngClass]="{ selected: selection != noneSelectedText, 'text-white': theme == 'light' }">{{ selection
        }}</span>&nbsp;
      <app-icon class="icon" svgName="down-arrow2" svgSize="12" bg="black" fill="none"></app-icon>
      <ng-template #invalidIcon>
        <app-icon class="icon" svgName="down-arrow2" svgSize="12" bg="black" fill="none"></app-icon>
      </ng-template>
    </button>
    <div class="dropdown-menu" [ngClass]="{ 'mt-0': true, 'sticky-opt': !stickyOpt }" [class.show]="open"
      role="combobox">
      <div class="bs-searchbox position-relative d-flex align-items-center w-100" *ngIf="liveSearch">
        <input #search class="form-control ds365-search" [formControl]="query" type="text" autocomplete="off"
          role="textbox" aria-label="Search" placeholder="{{ liveSearchPlaceholder }}" />
        <app-icon class="icon mb-1 icon-placement " svgName="search-1" svgSize="22"
          [svgStyle]="{ position: 'absolute', 'top.px': -10, 'right.px': 5 }">
        </app-icon>
      </div>
      <ul class="dropdown-menu inner" [class.show]="open" role="listbox" aria-expanded="false">
        <li [attr.data-original-index]="idx" *ngFor="let item of filteredItems; let idx = index"
          [class.selected]="item.selected" [class.spdivider]="item.divider" [ngClass]="{ 'sticky-opt': !stickyOpt }"
          (click)="toggleItem(item)">
          <a tabindex="{{ idx }}" [attr.data-tokens]="item.name" class="bs-option" role="option"
            [attr.aria-disabled]="item.disabled" [attr.aria-selected]="item.selected">
            <div class="d-flex align-items-center">
              <span *ngIf="multiple">
                <input type="checkbox" [checked]="item.selected" />
              </span>
              <span class="text ms-2" [innerHTML]="item.title" style="margin-top: 2px;"></span>
            </div>

          </a>
        </li>
        <li class="sticky-opt text-white"
          *ngIf="emptySearch && (!showOptionsOnSearch || (showOptionsOnSearch && query.value))">
          {{ noneResultsText }} "{{ query.valueChanges | async }}"
        </li>
      </ul>
    </div>
    <select style="display: none;" tabindex="-98" [attr.multiple]="multiple">
      <option *ngFor="let item of filteredItems" value="{{ item.id }}" [attr.selected]="item.selected ? true : null">
        {{ item.title }}</option>
    </select>
  </div>
</div>